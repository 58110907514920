<i18n>
{

  "de": {

  "cover-heading": "Geschlechts&shy;spezifische Hassausdrücke",
  "cover-subheading": "Identifizieren und klassifizieren anhand von Whatsapp Texten",
  "cover-button-secondary": "Über das Projekt",

  "intro-heading": "Worum geht’s?",
  "intro-text": "Hassausdrücke in den neuen Medien sind im Moment in aller Munde. Doch werden sie auch in privater Korrespondenz verwendet? Das Projekt wollte dies anhand von WhatsApps Texten, die von der Bevölkerung zur Verfügung gestellt wurden, herausfinden.",
  "intro-button": "Mehr erfahren",

  "identification-heading": "Aufgaben der Citizen Scientists",
  "identification-text": "In einem ersten Schritt (Identifikation) ging es darum, möglichst viele Texte anzuschauen und nach möglichen geschlechtsspezifischen Hassausdrücken zu suchen. Damit nicht alle 750'000 Nachrichten gelesen werden mussten, wurden die gefundenen Hassausdrücke der ersten 1000 bis 2000 Nachrichten auch in den noch nicht gelesenen Nachrichten gesucht.",

  "sources-heading": "Woher stammten die Texte?",
  "sources-text": "Die WhatsApp Gespräche, die hier Verwendung fanden, wurden uns 2014 von der Schweizer Bevölkerung zur Verfügung gestellt.",
  "sources-button": "Mehr erfahren"

  }

}
</i18n>

<template>
  <div>
    <app-cover imageUrl="/img/cover.jpg" goal="5" logoUrl="/img/logo-fnsnf.svg">
      <div class="row row-centered">
        <div class="col col-large-8">
          <h2 class="cover-heading scroll-effect" v-html="$t('cover-heading')"></h2>
        </div>
      </div>
      <div class="row row-centered">
        <div class="col col-large-8">
          <p class="cover-subheading scroll-effect scroll-effect-delayed-1">
            {{ $t("cover-subheading") }}
          </p>
        </div>
      </div>
      <div class="row row-centered">
        <div class="col col-large-8">
          <div class="button-group centered scroll-effect scroll-effect-delayed-2">
            <!-- <router-link tag="button" to="/identification" class="button button-primary">{{
              $t("cover-button-primary")
            }}</router-link> -->
            <router-link
              tag="button"
              to="/about"
              class="button button-primary"
              >{{ $t("cover-button-secondary") }}</router-link
            >
          </div>
        </div>
      </div>
    </app-cover>

    <!-- <app-content-section color="greyish" class="content-section-condensed">
      <div class="content-wrapper">
        <sub-section-stats
                :userCount="totalUserCount"
                :submissionCount="totalSubmissionCount"
                :taskCount="totalTaskCount" >
        </sub-section-stats>
      </div>
    </app-content-section> -->

    <app-content-section>
      <div class="content-wrapper">
        <div class="row row-centered row-middle row-wrapping">
          <div class="col col-10 col-tablet-portrait-8 col-large-6 col-wrapping scroll-effect">
            <div class="extra-padding-h">
              <img src="/img/graphic-intro.png" />
            </div>
          </div>

          <div
            class="col col-large-5 col-large-after-1 col-wrapping scroll-effect scroll-effect-delayed-1"
          >
            <div>
              <h2 class="heading centered left-aligned-large">{{ $t("intro-heading") }}</h2>
              <p v-html="$t('intro-text')"></p>
              <div class="button-group centered left-aligned-large">
                <router-link tag="button" to="/about" class="button button-secondary">{{
                  $t("intro-button")
                }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-content-section>

    <app-content-section color="light-greyish">
      <div class="content-wrapper">
        <div class="row row-centered row-middle row-reverse-large row-wrapping">
          <div class="col col-10 col-tablet-portrait-8 col-large-6 col-wrapping scroll-effect">
            <div>
              <div class="extra-padding-large-h">
                <img src="/img/graphic-bubbles.png" />
              </div>
            </div>
          </div>

          <div
            class="col col-large-5 col-large-before-1 col-wrapping scroll-effect scroll-effect-delayed-1"
          >
            <div>
              <h2 class="heading centered left-aligned-large">
                {{ $t("identification-heading") }}
              </h2>
              <p v-html="$t('identification-text')"></p>
              <div class="button-group centered left-aligned-large">
                <!-- <router-link tag="button" to="/identification" class="button button-primary">{{
                  $t("identification-button")
                }}</router-link> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-content-section>

    <app-content-section>
      <div class="content-wrapper">
        <div class="row row-centered row-middle row-wrapping">
          <div class="col col-10 col-tablet-portrait-8 col-large-6 col-wrapping scroll-effect">
            <div class="extra-padding-h">
              <img src="/img/people-with-phones.jpg" style="border-radius:50%" />
            </div>
          </div>

          <div
            class="col col-large-5 col-large-after-1 col-wrapping scroll-effect scroll-effect-delayed-1"
          >
            <div>
              <h2 class="heading centered left-aligned-large">{{ $t("sources-heading") }}</h2>
              <p v-html="$t('sources-text')"></p>
              <div class="button-group centered left-aligned-large">
                <router-link tag="button" to="/sources" class="button button-secondary">{{
                  $t("sources-button")
                }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-content-section>

    <section-feedback
      color="light-greyish"
      email="help@citizenscience.ch"
      :subject="$t('site-name')"
    ></section-feedback>

    <section-s-d-g goal="5" color="greyish">
      This project supports Goal 5: Achieve gender equality and empower all women and girls, and in
      particular target 5.2 on eliminating all forms of violence against all women in the public
      sphere.
    </section-s-d-g>

    <section-newsletter-signup></section-newsletter-signup>

    <app-footer></app-footer>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Cover from "@/components/shared/Cover.vue";
import ContentSection from "@/components/shared/ContentSection.vue";
import Footer from "@/components/shared/Footer.vue";
import SectionNewsletterSignup from "@/components/shared/SectionNewsletterSignup";
import SectionSDG from "../components/shared/SectionSDG";
import SectionFeedback from "../components/shared/SectionFeedback";
// import SubSectionStats from "../components/shared/SubSectionStats";

export default {
  name: "Home",
  components: {
    // SubSectionStats,
    SectionFeedback,
    SectionSDG,
    SectionNewsletterSignup,
    "app-cover": Cover,
    "app-content-section": ContentSection,
    "app-footer": Footer,
  },
  metaInfo: function() {
    return {
      title: this.$t("site-title"),
      titleTemplate: null,
      meta: [
        {
          property: "og:title",
          content: this.$t("site-title"),
        },
      ],
    };
  },
  computed: {
    // ...mapState({
    //     user: state => state.c3s.user,
    //     totalTaskCount: state => state.stats.totalTaskCount,
    //     totalUserCount: state => state.stats.totalUserCount,
    //     totalSubmissionCount: state => state.stats.totalSubmissionCount
    // })
  },
  // mounted() {
  //     this.$store.dispatch('stats/updateTotalUserAndSubmissionCount');
  //     this.$store.dispatch('stats/updateTotalTaskCount');
  // }
};
</script>

<style lang="scss"></style>
